    <ngx-json-ld [json]="schema"></ngx-json-ld>

    <div class="w-100 tip-padding position-fixed">
      <div class="row">
        <h4 class="u-header__banner-title">KOPA TOURS</h4>
      </div>
      <div class="row">
        <p class="u-header__banner-text">Kilimanjaro & Safaris</p>
      </div>
    </div>

    <div class="headline-grid">
      <div style="grid-area: 1 / 1 / 2 / 2; background-image: url(../../../../../assets/img/others/climb-out-in-front.jpg);background-position: 50% bottom; background-attachment: scroll, fixed; background-size: cover;"> </div>
      <div style="z-index:2;grid-area: 1 / 1 / 2 / 2; background-image: url(../../../../../assets/img/others/climb-out-in-front-foreground.png);background-position: 80% bottom; background-attachment: scroll, fixed; background-size: cover;"> </div>
    </div>

    <!-- Hero Section -->
    <div class="col-12 bg-default">
        <div class="container z-index-3 d-xs-flex align-items-xs-center space-top-2 space-top-sm-3 space-xs-0 min-height-xs-100vh">
            <!-- Content -->
            <div class="row w-100 top-padding position-relative z-index-2">
                <div class="col-9 col-md-7 col-lg-5 space-md-1">
                  <!-- SVG Bottom Shape -->
                  <figure class="position-absolute right-0 left-0 bottom-0 z-index-1 mb-lg-5 mb-xl-0">
                      <img
                           width="100%"
                           height="100%"
                           appLazyload="kopa-branding/full-logo.svg"
                           alt="Kopa Tours Logo">
                  </figure>
                  <!-- End SVG Bottom Shape -->
                  <div class="container position-relative" style="bottom:25%;left:3%">
                  </div>
                </div>
            </div>
            <!-- End Content -->
        </div>
    </div>
    <!-- End Hero Section -->

    <div class="bg-default position-relative">
      <!-- About Us Gallery -->
      <div class="container space-top-md-2">
        <div class="row w-100">
          <div class="col-12 col-md-5 mb-lg-0 space-bottom-3 space-bottom-sm-3 space-bottom-md-0">
            <div class="pr-md-4 mt-4 mt-md-0 mb-4">
              <!-- Title -->
              <h2 class="text-fg mb-2">CLIMB OUT IN <i style="font-weight:900;">FRONT</i> <sub>®</sub></h2>
              <p class="text-fg">Kopa Tours is the #1 African mountain outfitter.</p>
              <ul class="bigger">
                <li><img class="usBadge" alt="American Flag" src="../../../../assets/vendor/flag-icon-css/flags/4x3/us.svg"> Work with a USA-based crew</li>
                <li>🙋 All hikes are private - no groups</li>
                <li>⭐ Est. 2019, 300+ client summits</li>
                <li>⛰️ 98% summit success / A+ safety</li>
              </ul>
              <p class="text-fg">We climb because we want to improve, to be the best version of ourselves.</p>
              <app-kopa-button classes="btn-sm btn-wide transition-3d-hover" color="primary" link="/kilimanjaro/overview" text="Choose My Route"></app-kopa-button>
              <!-- End Title -->
            </div>
            <div class="pr-md-4">
              <!-- Title -->
              <h2 class="text-white mb-2">Explore with <span class="font-weight-semi-bold">heart</span></h2>
              <p class="text-fg"><i>Kopa</i> means "suit of hearts" in Swahili, and in everything we do at Kopa we do it with PASSION & we do it to have FUN.</p>
              <p class="text-fg">Vacations aren't about the box ticking, or the Instagram photos -- they are about immersing yourself in an experience.</p>
              <app-kopa-button classes="btn-sm btn-wide transition-3d-hover" color="secondary" link="/helpful-information/about-us" text="Read our Story"></app-kopa-button>
              <!-- End Title -->
            </div>
          </div>
          <div id='SVGDivider1' class="col-12 col-md-7 position-relative z-index-2 overflow-hidden mt-n11">
            <!-- Photo Gallery -->
            <div #wrapper>
              <div appFancyPhotoGallery *appVisibleWith="wrapper" [mosaic]="true" [photoGallery]="photoGallery"></div>
            </div>

            <!-- SVG Background Shape -->
            <figure class='w-100 centered-y px-5 z-index-n1'>
              <img  appLazyload='components/soft-triangle-shape.svg'
              alt='African animal photo gallery framing'
                   data-parent='#SVGDivider1'>
            </figure>
            <!-- End SVG Background Shape -->
          </div>
        </div>
      </div>

      <!-- Itineraries Section -->
      <div class="container space-top-2 mt-4">
          <div class="row">
              <div class="col-lg-5 order-4 order-lg-2">
                <div id="kiliItineraries" class="m-3" data-target-group="idItineraries">
                    <ng-container *ngFor="let card of pageOfKiliItems">
                      <div class="d-flex justify-content-center">
                        <div class="itin-slide">
                          <app-itinerary-card [ultraGlow]="true" [itineraryDetails]="card"></app-itinerary-card>
                        </div>
                      </div>
                    </ng-container>
                    <div class="limited-paging d-flex justify-content-center text-dark pb-0 pt-3">
                       <app-jw-pagination [items]="kiliItineraryCards" [initialPage]=2 [pageSize]=1 (changePage)="onChangeKiliPage($event)"></app-jw-pagination>
                    </div>
                </div>
                <div id="safariItineraries" style="display: none; opacity: 0;" class="m-3" data-target-group="idItineraries">
                  <ng-container *ngFor="let card of pageOfSafariItems">
                    <div class="d-flex justify-content-center">
                      <div class="itin-slide">
                        <app-itinerary-card [ultraGlow]="true" [itineraryDetails]="card" [safari]="true"></app-itinerary-card>
                      </div>
                    </div>
                  </ng-container>
                  <div class="limited-paging d-flex justify-content-center text-dark pb-0 pt-3">
                     <app-jw-pagination [items]="safariItineraryCards" [initialPage]=2 [pageSize]=1 (changePage)="onChangeSafariPage($event)"></app-jw-pagination>
                  </div>
                </div>
              </div>
              <div class="col-lg-1 order-1 order-lg-3">
              </div>
              <div class="col-lg-5 order-2 order-lg-4 mb-7 mb-lg-0 space-top-lg-3">
                  <!-- Title -->
                  <div class="pr-lg-4 mb-7">
                      <h2 class="text-white mb-2">Trip Offerings</h2>
                      <h4 class="text-white" style="font-weight:400;">From the <strong>plains</strong> ... to the <strong>peaks</strong>. We've got it all.</h4>
                      <p class="text-fg-alt">Choose the package type that suits your needs.</p>
                  </div>
                  <!-- End Title -->

                  <!-- Button Group -->
                  <div class="btn-group btn-group-toggle">
                      <a class="js-animation-link btn btn-outline-secondary btn-custom-toggle-secondary btn-sm-wide active" href="javascript:;"
                         data-target="#kiliItineraries"
                         data-link-group="idItineraries"
                         data-animation-in="zoomIn faster">Kilimanjaro</a>
                      <a class="js-animation-link btn btn-outline-secondary btn-custom-toggle-secondary btn-sm-wide" href="javascript:;"
                         data-target="#safariItineraries"
                         data-link-group="idItineraries"
                         data-animation-in="zoomIn faster">
                          Safaris
                          <span class="badge badge-dark badge-pill badge-bigger badge-pos">EXPLORE</span>
                      </a>
                  </div>
                  <!-- End Button Group -->
              </div>
          </div>
      </div>
      <!-- End Itineraries Section -->

      <div class="container space-top-1 space-top-md-2">
          <div class="text-center space-bottom-2">
            <h3 class="text-white mb-n4">Testimonials</h3>
          </div>
          <app-testimonials></app-testimonials>
      </div>

      <div class="container text-center space-bottom-2">
        <figure class="center">
          <img
           width="280" height="81"
           appLazyload="kopa-branding/Web_Text.svg"
           alt="Kopa Tours">
        </figure>
        <br />
        <h1 class="h5">Kilimanjaro climbs <br *ngIf="isSmallerThanIpad()"/><span class="text-gray mx-1"> - </span><br *ngIf="isSmallerThanIpad()"/> Safari journeys <br *ngIf="isSmallerThanIpad()"/><span class="text-gray mx-1"> - </span><br *ngIf="isSmallerThanIpad()"/> Competitive pricing <br *ngIf="isSmallerThanIpad()"/><span class="text-gray mx-1"> - </span><br *ngIf="isSmallerThanIpad()"/> Safe travels</h1>
      </div>
    </div>

    <!-- <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/"             title="Flaticon">www.flaticon.com</a></div> -->
