import {Injectable, EventEmitter} from '@angular/core';

export enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

@Injectable({
  providedIn: 'root'
})
export class StickyHeaderService {
  public visibilityChanged: EventEmitter<VisibilityState>;
  private stickyHeaderHeight: number;
  private stickyHeaderVis: VisibilityState;

  constructor() {
      this.visibilityChanged = new EventEmitter();
      this.stickyHeaderHeight = 0;
      this.stickyHeaderVis = VisibilityState.Visible;
  }

  public getStickyHeaderHeight(): number {
    return this.stickyHeaderHeight;
  }

  public getStickyHeaderVis(): VisibilityState {
    return this.stickyHeaderVis;
  }

  public toggleVisibility(vis: VisibilityState, ht: number): void {
    this.stickyHeaderVis = vis;
    this.stickyHeaderHeight = ht;
    this.visibilityChanged.emit(vis);
  }
}
