import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {QuotationMarkComponent} from "./quotation-mark/quotation-mark.component";



@NgModule({
  declarations: [
    QuotationMarkComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    QuotationMarkComponent
  ]
})
export class HelpersModule { }
