import {Directive, Input, ElementRef, OnInit} from '@angular/core';
import {PhotoGallerySettings} from '../../widgets/fancy-photo-gallery/fancy-photo-gallery/fancy-photo-classes';
import {LazyLoaderService} from '../../../core/services/lazy-loader/lazy-loader.service';

@Directive({
    selector: '[appFancyPhotoGallery]'
})
export class FancyPhotoGalleryDirective implements OnInit {

  // metadata for the photos in the gallery.
  @Input() photoGallery: PhotoGallerySettings;

  // if mosaic, image thumbnails are displayed in dramatic fashion
  // NOTE: mosaic must have at least 4 photos to function!
  @Input() mosaic: boolean;

  // how many thumbnails to show on the content page.
  @Input() thumbnailCount: number;

  @Input() clsPlaceholderHeight: string;

  isInitialized: boolean;
  componentEl: HTMLElement;

  onLoadFancyPhotoGallery() {
    this.lazyLoader.load('app-fancy-photo-gallery').then(inComponentEl => {
      this.isInitialized = true;
      this.componentEl = inComponentEl;
      this.componentEl['photoGallery'] = this.photoGallery;
      this.componentEl['mosaic'] = this.mosaic;
      this.componentEl['thumbnailCount'] = this.thumbnailCount;
      this.componentEl['clsPlaceholderHeight'] = this.clsPlaceholderHeight;
      this.elementRef.nativeElement.appendChild(this.componentEl);
    });
  }

  constructor(
    private elementRef: ElementRef,
    private lazyLoader: LazyLoaderService) {
      this.isInitialized = false;
  }

  ngOnInit() {
    this.onLoadFancyPhotoGallery();
  }

  forceShow() {
    if (this.isInitialized) {
      this.componentEl.click();
    }
  }
}
