import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AgmCoreModule} from '@agm/core';
import {NgxJsonLdModule} from '@ngx-lite/json-ld';
import {NgxTypedJsModule} from 'ngx-typed-js';

import {AccordionConfig, AccordionModule} from 'ngx-bootstrap/accordion';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {ModalContainerComponent, ModalBackdropComponent} from "ngx-bootstrap/modal";
import {RequestDiscountVoucherComponent} from "./features/widgets-module/components/request-discount-voucher/request-discount-voucher.component";
import {SocialLinksComponent} from './core/components/social-links/social-links.component';
import {HelpersModule} from "./features/helpers-module/helpers.module";

// Pages
import {AppRoutingModule} from './app-routing.module';
import {HomePageComponent} from './core/components/home-page/home-page.component';
import {NavBarComponent} from './core/components/nav-bar/nav-bar.component';
import {TopHelpBarComponent} from './core/components/top-help-bar/top-help-bar.component';
import {AppFooterComponent} from './core/components/app-footer/app-footer.component';
import {PageNotFoundComponent} from './core/components/page-not-found/page-not-found.component';

// Widgets
import {WidgetsModule} from './features/widgets-module/widgets.module';
import {DirectivesModule} from './features/directives-module/directives.module';

// Services
import {SurveyService} from './core/services/survey/survey.service';
import {NavMenuRequesterService} from './core/services/nav-menu-requester/nav-menu-requester.service';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    NavBarComponent,
    TopHelpBarComponent,
    AppFooterComponent,
    PageNotFoundComponent,
    SocialLinksComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAsNDMdMLGTYpuzmDysR6K-HPsqT5qYMTU' // note: sourced from LineTD
    }),
    AccordionModule.forRoot(),
    BrowserAnimationsModule,
    WidgetsModule,
    HelpersModule,
    DirectivesModule,
    TooltipModule.forRoot(),
    NgxJsonLdModule,
    NgxTypedJsModule
  ],
  providers: [
    {provide: AccordionConfig, useValue: {closeOthers: true, isAnimated: false}},
    SurveyService,
    NavMenuRequesterService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalContainerComponent,
    ModalBackdropComponent,
    RequestDiscountVoucherComponent
  ]
})
export class AppModule {
}
