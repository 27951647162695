import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class NavMenuRequesterService {

  private emitter: Subject<any> = new Subject<any>();
  private lastRequestedLevel = -1;
  private lastRequestedIndex = -1;

  constructor() { }

  openMenuAtLevel(inLevel: number, inIndex: number) {
    this.lastRequestedLevel = inLevel;
    this.lastRequestedIndex = inIndex;
    this.emitter.next({command: 'nav', params: {level:inLevel, index:inIndex}});
  }
  getChangeEmitter() {
    return this.emitter;
  }
  getLastRequestedLevel() {
    return this.lastRequestedLevel;
  }
  getLastRequestedIndex() {
    return this.lastRequestedIndex;
  }
}
