<div id="logoAndNav" class="d-xl-none color-and-z-index mx-0 py-2">
  <div class="container">
    <!-- Nav -->
    <nav class="navbar navbar-expand-lg px-0 py-0" style="justify-content: center;">
      <!-- Logo -->
      <a class="navbar-brand u-header__navbar-brand u-header__navbar-brand-center" routerLink="/home"
         style="width: inherit; margin-right: 0">
        <img class="px-xl-0" id="kopaLogo"
             src="../../../../../assets/svg/kopa-branding/full-logo-no-words.svg">
        <figure class="center">
          <img style="width: 280px" src="../../../../../assets/svg/kopa-branding/Web_Text.svg" id="kopaWords"
               alt="Kopa Tours">
        </figure>
      </a>
      <!-- End Logo -->
    </nav>
    <!-- End Nav -->
  </div>
</div>
<div id="borderBar" class="borderBar"></div>
