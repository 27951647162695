import { NgModuleFactory, Type } from '@angular/core';

export const lazyWidgets: { name: string, loadChildren: () => Promise<NgModuleFactory<any> | Type<any>> }[] = [
  {
    name: 'app-hotel-cubes',
    loadChildren: () => import('../../../features/widgets/hotel-cubes/hotel-cubes.module').then(m => m.HotelCubesModule)
  },
  {
    name: 'app-park-cubes',
    loadChildren: () => import('../../../features/widgets/parks-cubes/park-cubes.module').then(m => m.ParkCubesModule)
  },
  {
    name: 'app-fancy-photo-gallery',
    loadChildren: () => import('../../../features/widgets/fancy-photo-gallery/fancy-photo-gallery.module').then(m => m.FancyPhotoGalleryModule)
  },
  {
    name: 'app-elevation-graph',
    loadChildren: () => import('../../../features/widgets/elevation-graph/elevation-graph.module').then(m => m.ElevationGraphModule)
  },
  {
    name: 'app-survey',
    loadChildren: () => import('../../../features/widgets/survey/survey.module').then(m => m.SurveyModule)
  },
  {
    name: 'app-nav-menu',
    loadChildren: () => import('../../../features/widgets/nav-menu/nav-menu.module').then(m => m.NavMenuModule)
  }
];

export function lazyArrayToObj() {
  const result = {};
  for (const w of lazyWidgets) {
    result[w.name] = w.loadChildren;
  }
  return result;
}
