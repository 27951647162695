import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import { lazySizes } from 'lazysizes';
import '../../../../assets/js/modernizr-custom.js';

declare const Modernizr: any;

@Directive({
  selector: '[appLazyload]',
  exportAs: 'lazyload'
})
export class LazyloadDirective implements OnInit {
  @Input('appLazyload') imgSrc: string;
  @Input() appWidth = 0;
  @Input() appHeight = 0;
  public img: string;
  public webpImg: string;
  private webpSupported = false;
  private isLocalHost = false;
  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    const host = window.location.hostname;
    const self = this;

    self.isLocalHost = (host === 'localhost' && window.location.port === '4200');

    if (self.imgSrc.endsWith('.svg')) {
      if (!self.isLocalHost) {
        // use "min.svg" format on deployed software
        self.imgSrc = this.imgSrc.substr(0, this.imgSrc.lastIndexOf(".")) + ".min.svg";
      }
      self.img = `../../../../assets/svg/${self.imgSrc}`;
    } else {
      self.img = `../../../../assets/img/${self.imgSrc}`;

      // use "webp" format on deployed software (but keep original as fallback)
      if (!self.isLocalHost && !self.imgSrc.endsWith('.gif')) {
        const webpImgSrc = this.imgSrc.substr(0, this.imgSrc.lastIndexOf(".")) + ".webp";
        self.webpImg = `../../../../assets/img/${webpImgSrc}`;
      }
    }

    self.initLazyLoading();
    self.setAttributes();
  }

  placeholderSrc(w: number, h: number) {
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"%3E%3C/svg%3E`;
  }

  initLazyLoading() {
    if (lazySizes) {
      lazySizes.init();
    }
  }

  setAttributes() {
    if (this.el.nativeElement.localName === 'img') {
      this.renderer.addClass(this.el.nativeElement, 'lazyload');
      this.setImgSrc();
    } else {
      this.setElementBackgroundImage();
    }
  }

  getImageType(fileExt: string) {
    if (fileExt === 'jpg' || fileExt === 'jpeg') {
      return 'image/jpeg';
    } else if (fileExt === 'png') {
      return 'image/png';
    } else if (fileExt === 'gif') {
      return 'image/gif';
    }
    return '';
  }

  setImgSrc() {
    if (!this.isLocalHost) {
      this.renderer.setAttribute(this.el.nativeElement, 'data-src', this.img);
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.placeholderSrc(this.appWidth, this.appHeight));

      const fileExt = this.imgSrc.split('.').pop();
      if (fileExt !== 'svg' && fileExt !== 'gif') {
        const picWrap = this.renderer.createElement('picture');

        const imgSource = this.renderer.createElement('source');
        this.renderer.setAttribute(imgSource, 'data-srcset', this.img);
        this.renderer.setAttribute(imgSource, 'type', this.getImageType(fileExt));

        const webpSource = this.renderer.createElement('source');
        this.renderer.setAttribute(webpSource, 'data-srcset', this.webpImg);
        this.renderer.setAttribute(webpSource, 'type', 'image/webp');

        this.renderer.appendChild(picWrap, webpSource);
        this.renderer.appendChild(picWrap, imgSource);

        const parent = this.el.nativeElement.parentNode;
        const refChild = this.el.nativeElement;

        this.renderer.insertBefore(parent, picWrap, refChild);
        this.renderer.appendChild(picWrap, refChild);
      }
    } else {
      this.renderer.setAttribute(this.el.nativeElement, 'data-src', this.img);
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.placeholderSrc(this.appWidth, this.appHeight));
    }
  }
  setElementBackgroundImage() {
    const self = this;
    const urlStr = this.placeholderSrc(this.appWidth, this.appHeight);
    if (!self.isLocalHost) {
      Modernizr.on('webp', ( result: boolean ) => {
        self.webpSupported = result;
        self.renderer.addClass(self.el.nativeElement, 'lazyload');
        if (self.webpSupported) {
          self.img = self.webpImg;
        }

        self.renderer.setAttribute(self.el.nativeElement, 'data-bg', self.img);
        self.renderer.setStyle(self.el.nativeElement, 'background-image', `url(${urlStr})`);
      });
    } else {
      self.renderer.addClass(self.el.nativeElement, 'lazyload');
      self.renderer.setAttribute(self.el.nativeElement, 'data-bg', self.img);
      self.renderer.setStyle(self.el.nativeElement, 'background-image', `url(${urlStr})`);
    }
  }
}
