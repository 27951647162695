export class Testimonial {
  constructor(options?: {
    description: string;
    person: string;
    location: string;
    thumbnailPath: string;
    altThumbnailDescription: string; }) {
      if (options) {
          this.description = options.description;
          this.person = options.person;
          this.location = options.location;
          this.thumbnailPath = options.thumbnailPath;
          this.altThumbnailDescription = options.altThumbnailDescription;
      }
  }
  description: string;
  person: string;
  location: string;
  thumbnailPath: string;

  // alternate description for when a photo cannot load.
  altThumbnailDescription: string;
}
