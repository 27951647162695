import {Component, HostListener, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized, Scroll} from '@angular/router';
import {StickyHeaderService, VisibilityState} from './core/services/sticky-header/sticky-header.service';
import {LazyLoaderService} from './core/services/lazy-loader/lazy-loader.service';
import {NavMenuRequesterService} from './core/services/nav-menu-requester/nav-menu-requester.service';
import {Meta, Title} from '@angular/platform-browser';
import {fromEvent, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, pairwise, share, throttleTime, tap} from 'rxjs/operators';

import '../assets/vendor/svg-injector/dist/svg-injector.min.js';
import {animate, state, style, transition, trigger} from "@angular/animations";

declare var $: any;
declare let gtag: Function;
declare global {
  interface Window {
    __lc: any;
    dataLayer: any;
  }
}

enum Direction {
  Up = 'Up',
  Down = 'Down',
  Invalid = 'Invalid'
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('toggle', [
      state(VisibilityState.Hidden, style({
        transform: 'translateY(-100%)'
      })),
      state(VisibilityState.Visible, style({
        transform: 'translateY(0)'
      })),
      transition('* => *', animate('125ms ease-in'))
    ])
  ]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  private _routeScrollPositions: { [url: string]: number }[] = [];
  private _subscriptions: Subscription[] = [];

  @ViewChild('stickyHeader') stickyHeaderElementRef: ElementRef;
  private isStickyHeaderVisible = true;

  @ViewChild("navDiv", {read: ElementRef}) navDiv: ElementRef;
  navEl: HTMLElement;
  navLoaded = false;

  private halfScreenHeight: number;
  private pendingHashChange = false;

  loadAPI: Promise<any>;
  title = 'KopaKiliAngular';

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    sessionStorage.removeItem('previousUrl');
    sessionStorage.removeItem('modalShown');
  }

  get getToggle(): VisibilityState {
    return this.isStickyHeaderVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  @HostListener('window:resize', [])
  onResize() {
    this.halfScreenHeight = window.innerHeight * 0.5;
    if (window.innerWidth >= 1200) {
      this.loadNavMenu();
    }
  }

  @HostListener('window:beforeunload', [])
  onSiteLeave() {
    this.ngOnDestroy();
  }

  ngOnInit() {
    this.halfScreenHeight = window.innerHeight * 0.5;
    // tslint:disable-next-line:only-arrow-functions
    const self = this;
    $(window).on('load', () => {
      self.determineScrollDirectionAndHeight();
    });

    if (window.innerWidth >= 1200) {
      this.loadNavMenu();
    }

    this._subscriptions.push(
      // save or restore scroll position on route change
      // initialize addThis on specific routes
      this.router.events.pipe(
        filter(e => e instanceof RoutesRecognized), pairwise()
      ).subscribe(([prevRouteEvent, currRouteEvent]) => {
        // @ts-ignore
        if (currRouteEvent.url) {
          // @ts-ignore
          this.determineVisibilityOfShareButtons(currRouteEvent.url);
        }
        if (prevRouteEvent instanceof NavigationEnd && currRouteEvent instanceof NavigationStart) {
          this._routeScrollPositions[prevRouteEvent.url] = window.pageYOffset - 30;
        }
        if (currRouteEvent instanceof NavigationEnd) {
          window.scrollTo(0, this._routeScrollPositions[currRouteEvent.url] || 0);
        }
      })
    );

    $(document).on('ready', () => {
      // initialization of show animations
      $.HSCore.components.HSShowAnimation.init('.js-animation-link');
    });
  }

  loadNavMenu() {
    if (!this.navLoaded) {
      this.lazyLoader.load('app-nav-menu').then(inComponentEl => {
        this.navEl = inComponentEl;
        this.navDiv.nativeElement.appendChild(this.navEl);
      });
      this.navLoaded = true;
    }
  }

  determineVisibilityOfShareButtons(route) {
    const addThisDesktop = document.getElementsByClassName('addthis-smartlayers')[0];
    const addThisMobile = document.getElementsByClassName('addthis-smartlayers-mobile')[0];
    if (route.indexOf('/blog/post') === -1 && route.indexOf('climate-zones') === -1 && route.indexOf('packing-list')) {
      if (addThisDesktop && addThisMobile) {
        // @ts-ignore
        addThisDesktop.style.display = 'none';
        // @ts-ignore
        addThisMobile.style.display = 'none';
      }
    } else if (addThisDesktop && addThisMobile) {
      // @ts-ignore
      addThisDesktop.style.display = 'block';
      // @ts-ignore
      addThisMobile.style.display = 'block';
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private stickyHeaderService: StickyHeaderService,
    private lazyLoader: LazyLoaderService,
    private navMenuRequesterService: NavMenuRequesterService
  ) {

    this.router.events.subscribe(event => {
      // Google Analytics
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-149936234-1',
          {
            page_path: event.urlAfterRedirects,
          }
        );
      } else if (event instanceof NavigationStart) {
        this.pendingHashChange = true;
      }
      if (event instanceof Scroll && event.anchor) {
        setTimeout(() => {
          this.scroll('#' + event.anchor);
        }, 100);
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        const metaBlobKey = 'metaBlob';
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data[metaBlobKey]) {
            return child.snapshot.data[metaBlobKey];
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((metaBlob: any) => {

      const titleKey = 'title';
      const descriptionKey = 'description';
      const twitterImageKey = 'twitterImage';
      const twitterDescKey = 'twitterDesc';
      const levelKey = 'level';
      const indexKey = 'index';
      const twitterTitleKey = 'twitterTitle';
      const statusCodeKey = 'statusCode';
      // default to fall-back on, when a page is missing a title.
      let title = 'Kopa Tours | Explore Tanzania | Kilimanjaro and Safari Trips';
      // default meta descripion tag, to fall-back on when not defined.
      let description = `Travel with us to Tanzania - Get up close with Elephants, Rhinos, and Lions on our Wild Camping safaris. Climb to the Roof of Africa atop Kilimanjaro!`;
      let twitterImage = '480x320/kilimanjaro-and-wildflowers-sm.jpg';
      let twitterDesc = description;
      let twitterTitle = title;

      let statusCode = '200';

      if (metaBlob) {
        if (metaBlob[titleKey]) {
          title = metaBlob[titleKey];
          if (metaBlob[titleKey].length <= 51) {
            title = title + ' | Kopa Tours';
          }
          this.titleService.setTitle(title);
        }
        if (metaBlob[descriptionKey]) {
          description = metaBlob[descriptionKey];
          this.meta.removeTag('name="description"');
          this.meta.addTag({
            name: 'description',
            content: description
          });
        }
        if (metaBlob[levelKey] && metaBlob[indexKey]) {
          this.navMenuRequesterService.openMenuAtLevel(metaBlob[levelKey], metaBlob[indexKey]);
        }
        if (metaBlob[twitterImageKey]) {
          twitterImage = metaBlob[twitterImageKey];

          this.meta.removeTag('name="twitter:image:src"');
          this.meta.addTag({
            name: 'twitter:image:src',
            content: 'https://kopatours.com/assets/img/' + twitterImage
          });

          this.meta.removeTag('name="og:image"');
          this.meta.addTag({name: 'og:image', content: 'https://kopatours.com/assets/img/' + twitterImage});

          this.meta.removeTag('name="og:image:secure_url"');
          this.meta.addTag({
            name: 'og:image:secure_url',
            content: 'https://kopatours.com/assets/img/' + twitterImage
          });
        }
        if (metaBlob[twitterDescKey]) {
          twitterDesc = metaBlob[twitterDescKey];
          this.meta.removeTag('name="twitter:description"');
          this.meta.addTag({
            name: 'twitter:description',
            content: twitterDesc
          });
        }
        if (metaBlob[twitterTitleKey]) {
          twitterTitle = metaBlob[twitterTitleKey];
          this.meta.removeTag('name="twitter:title"');
          this.meta.addTag({name: 'twitter:title', content: twitterTitle});
        }
        if (metaBlob[statusCodeKey]) {
          statusCode = metaBlob[statusCodeKey];
        }
      }

      this.meta.removeTag('name="prerender-status-code"');
      if (statusCode !== '200') {
        this.meta.addTag({name: 'prerender-status-code', content: statusCode});
      }
    });
  }

  private scroll(query: string) {
    const targetElement = document.querySelector(query);
    if (!targetElement) {
      window.scrollTo(0, 0);
    } else if (!this.isInViewport(targetElement)) {
      targetElement.scrollIntoView();
    }
  }

  private isInViewport = (elem: any) => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  ngAfterViewInit() {
    this.stickyHeaderService.toggleVisibility(VisibilityState.Visible, this.stickyHeaderElementRef.nativeElement.offsetHeight);
  }

  determineScrollDirectionAndHeight() {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(1),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]): Direction => (
        this.pendingHashChange ? // keeps the header in view when jumping between anchor links
          Direction.Up : (
            (y2 < this.halfScreenHeight) ? Direction.Invalid : // keeps the header in view for the initial top of page scrolling
              (y2 < y1 ? Direction.Up : Direction.Down)))),
      tap(() => {
        this.pendingHashChange = false;
      }),
      filter(direction => direction !== Direction.Invalid),
      distinctUntilChanged(),
      share()
    );

    const scrollUp = scroll$.pipe(
      filter(direction => direction === Direction.Up)
    );

    const scrollDown = scroll$.pipe(
      filter(direction => direction === Direction.Down)
    );

    scrollUp.subscribe(() => {
      this.isStickyHeaderVisible = true;
      this.stickyHeaderService.toggleVisibility(VisibilityState.Visible, this.stickyHeaderElementRef.nativeElement.offsetHeight);
    });
    scrollDown.subscribe(() => {
      this.isStickyHeaderVisible = false;
      this.stickyHeaderService.toggleVisibility(VisibilityState.Hidden, this.stickyHeaderElementRef.nativeElement.offsetHeight);
    });
  }
}
