import {Component, OnInit, AfterViewChecked} from '@angular/core';
import {getKiliItineraryCardsData, getSafariItineraryCardsData} from '../../shared/itineraries-data';
import {ItineraryDetailsModel} from '../../../features/widgets-module/components/itinerary-card/itinerary-details.model';
import {
    Photo,
    PhotoGallerySettings
} from '../../../features/widgets/fancy-photo-gallery/fancy-photo-gallery/fancy-photo-classes';
import {Schema} from './json-ld-schema';
import {isSmallerThanIpad} from '../../../../helpers';

declare var $: any;

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit, AfterViewChecked {

    isSmallerThanIpad = isSmallerThanIpad;

    kiliItineraryCards: ItineraryDetailsModel[] = [];
    pageOfKiliItems: Array<any>;
    safariItineraryCards: ItineraryDetailsModel[] = [];
    pageOfSafariItems: Array<any>;

    // photo gallery metadata
    photoGallery: PhotoGallerySettings;

    schema = Schema;

    constructor() {
    }

    public isMobile() {
        return document.body.clientWidth <= 767.98;
    }

    onChangeKiliPage(pageOfItems: any) {
        // update current page of items
        this.pageOfKiliItems = pageOfItems.content;
    }

    onChangeSafariPage(pageOfItems: any) {
        // update current page of items
        this.pageOfSafariItems = pageOfItems.content;
    }

    ngAfterViewChecked() {
      $.HSCore.components.HSShowAnimation.init('.js-animation-link', {
            afterShow: () => {
                // reinitialize, show images can properly render
                this.initItineraryCards();
            }
      });
    }

    ngOnInit() {
        this.initItineraryCards();

        this.photoGallery = new PhotoGallerySettings({
            iconColor: 'dark',
            photos:
                [
                    new Photo({
                        title: 'First Glimpse',
                        description: `When you catch your first glimpse of the peaks of Mount Kilimanjaro,
            take in the scale of journey you are embarking on.`,
                        thumbnailPath: '500x375/kilimanjaro-sight.jpg',
                        highresPath: '../../assets/img/others/kilimanjaro-sight.jpg',
                        altPhotoDescription: 'View of Mount Kilimanjaro\'s peaks.'
                    }),
                    new Photo({
                        title: 'Elephant on a Stroll',
                        description: `An adult elephant crosses the road at Lake Manyara National Park --
            on the lookout for some cool, refreshing water.`,
                        thumbnailPath: '370x430/manyara-elephant.jpg',
                        highresPath: '../../assets/img/others/manyara-elephant.jpg',
                        altPhotoDescription: 'Adult African Elephant on Safari in Lake Manyara.'
                    }),
                    new Photo({
                        title: 'Red-and-Yellow Barbet',
                        description: `Tanzanian National parks are filled with a variety of exotic birds.
            The red-and-yellow barbet is one such case, with extraordinary beauty.`,
                        thumbnailPath: '460x500/regal-bird.jpg',
                        highresPath: '../../assets/img/others/regal-bird.jpg',
                        altPhotoDescription: 'Red-and-Yellow Barbet'
                    }),
                    new Photo({
                        title: 'You\'ll Be in My Heart',
                        description: `Lioness's look after their cubs for roughly
             two years before they begin to fend for themselves. This mother and cub
             enjoy one another's company while resting on a shady kopje.`,
                        thumbnailPath: '480x320/mom-cub-resize.jpg',
                        highresPath: '../../assets/img/others/mom-cub.jpg',
                        altPhotoDescription: 'A lioness and her cub rest on a kopje.'
                    }),
                    new Photo({
                        title: 'Power Nap',
                        description: `Hippos tend to rest for short periods at a time, rather than long stretches.
             Hippos are able to sleep underwater and rise to the surface for air without
             ever waking up!`,
                        thumbnailPath: '',
                        highresPath: '../../assets/img/others/sleepy-hippo.jpg',
                        altPhotoDescription: 'A hippo bobs above the water, with its eyes closed.'
                    }),
                    new Photo({
                        title: 'Best View in the World',
                        description: `The Serengeti Wild Camps offer a magical and serene view each and every day.
             Look out from your exotic tent to see herds of prey animals peacefully co-existing
            amongst one another - truly the best view in the world!`,
                        thumbnailPath: '',
                        highresPath: '../../assets/img/others/serengeti-view.jpg',
                        altPhotoDescription: 'Zebra and thomson gazelle graze in the distance in the grasslands.'
                    }),
                    new Photo({
                        title: 'The Great Migration',
                        description: `The Serengeti Plains are the only place in the world to witness
             the magnificent Wildebeest Migration. Over two million wildebeest migrate
             each year in search of greener pasture.`,
                        thumbnailPath: '',
                        highresPath: '../../assets/img/others/wildebeest-migration.jpg',
                        altPhotoDescription: 'A herd of wildebeest cross a dry, flat landscape.'
                    }),
                    new Photo({
                        title: 'Seeing Stripes',
                        description: `Zebra\'s have totally unique stripe patterns, not two are exactly alike.`,
                        thumbnailPath: '',
                        highresPath: '../../assets/img/others/zebra-arusha.jpg',
                        altPhotoDescription: 'A single zebra looks as tourists pass by.'
                    }),
                    new Photo({
                        title: 'Gallant Giraffe',
                        description: `These long legged animals can be seen throughout the Serengeti National Park.`,
                        thumbnailPath: '',
                        highresPath: '../../assets/img/others/serengeti-giraffe.jpg',
                        altPhotoDescription: 'A single adult giraffe looks for food.'
                    }),
                    new Photo({
                        title: 'Campsite Sweet Campsite',
                        description: `The days you spend on Kilimanjaro will be a wonderful adventure,
          look forward to rest at your campsite after each exciting day.`,
                        thumbnailPath: '',
                        highresPath: '../../assets/img/1920x1080/campsite.jpg',
                        altPhotoDescription: 'Campsite on Kilimanjaro.'
                    }),
                    new Photo({
                        title: 'Starry Nights',
                        description: `High above the clouds, the camps of Kilimanjaro offer spectacular star viewing at night.`,
                        thumbnailPath: '',
                        highresPath: '../../assets/img/1920x1080/sky-kilimanjaro.jpg',
                        altPhotoDescription: 'Night time starry sky on Mount Kilimanjaro.'
                    }),
                    new Photo({
                        title: 'Teamwork',
                        description: `The porters of Kilimanjaro make your climb possible.
                        They carry all the parts of camp to each campsite,
                        set it up before you get there, and tear it down once you leave.
                         Without them, your trek would be a lot more challenging.`,
                        thumbnailPath: '',
                        highresPath: '../../assets/img/others/porters-kili.jpg',
                        altPhotoDescription: 'Porters climbing on Mount Kilimanjaro.'
                    }),
                    new Photo({
                        title: 'Sunrise on Kilimanjaro\'s Peak',
                        description: `Be sure to take in the spectacular sunrise on Summit day.`,
                        thumbnailPath: '',
                        highresPath: '../../assets/img/1920x1080/kilimanjaro-sunrise.jpg',
                        altPhotoDescription: 'Sunrise on Mount Kilimanjaro.'
                    })
                ]
        });
    }

    initItineraryCards() {
        this.safariItineraryCards = getSafariItineraryCardsData();
        this.kiliItineraryCards = getKiliItineraryCardsData();
    }
}
