<!-- Testimonials -->
<div class="container overflow-hidden">
  <!-- SVG Quote -->
  <figure class="text-center mb-5 mx-auto">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px"
       viewBox="0 0 8 8" style="enable-background:new 0 0 8 8;" xml:space="preserve">
      <path style="fill:var(--fg-alt-color)!important" d="M3,1.3C2,1.7,1.2,2.7,1.2,3.6c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5
        C1.4,6.9,1,6.6,0.7,6.1C0.4,5.6,0.3,4.9,0.3,4.5c0-1.6,0.8-2.9,2.5-3.7L3,1.3z M7.1,1.3c-1,0.4-1.8,1.4-1.8,2.3
        c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5c-0.7,0-1.1-0.3-1.4-0.8
        C4.4,5.6,4.4,4.9,4.4,4.5c0-1.6,0.8-2.9,2.5-3.7L7.1,1.3z"/>
    </svg>
  </figure>
  <!-- End SVG Quote -->

  <!-- Testimonials Carousel Main -->
  <carousel class="carousel w-80 w-lg-60 text-center mx-auto" id="testimonialCarousel"
            [loop]=true
            [arrowsOutside]=true
            [cellsToShow]=1
            [overflowCellsLimit]=1
            [lightDOM]=true>
    <div *ngFor="let entry of testimonials" class="carousel-cell">
      <!-- Testimonials -->
      <div>
        <blockquote class="h3">{{entry.description}}</blockquote>
        <h4 class="h6 text-fg-alt">{{entry.person}}, {{entry.location}}</h4>
      </div>
      <div class="u-avatar u-xl-avatar--bordered rounded-circle mx-auto">
          <img class="img-fluid rounded-circle" appWidth=100 appHeight=100 [appLazyload]="entry.thumbnailPath"
               [alt]="entry.altThumbnailDescription">
      </div>
      <!-- End Testimonials -->
    </div>
  </carousel>
  <!-- End Testimonials Carousel Main -->
</div>
<!-- End Testimonials -->
