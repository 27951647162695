export class Photo {
  constructor(options?: { path?: string; title: string; description: string; thumbnailPath: string; highresPath: string; altPhotoDescription: string; location?: string; textColor?: string; textWidth?: string; mobileColor?: string; attribution?: string; }) {
      if (options) {
          this.title = options.title;
          this.description = options.description;
          this.thumbnailPath = options.thumbnailPath;
          this.highresPath = options.highresPath;
          this.altPhotoDescription = options.altPhotoDescription;
      }
      if (options.location) {
          this.location = options.location;
      }
      if(options.path) {
        this.path = options.path;
      }
      if(options.mobileColor) {
          this.mobileColor = options.mobileColor;
      }
      if (options.textColor) {
          this.textColor = options.textColor;
      }
      if (options.textWidth) {
          this.textWidth = options.textWidth;
      }
      if(options.attribution) {
        this.attribution = options.attribution;
      }
  }
  path?: string;
  title: string;
  description: string;
  thumbnailPath: string;
  highresPath: string;
  location?: string;
  textColor?: string;
  mobileColor?: string;
  textWidth = '30';
  attribution?: string;

  // alternate description for when a photo cannot load.
  altPhotoDescription: string;
}

export class PhotoGallerySettings {
  constructor(options?: { photos: Photo[]; iconColor: string; }) {
      if (options) {
          this.photos = options.photos;
          this.iconColor = options.iconColor;
      }
  }

  // the array of photos and their respective metadatas
  photos: Photo[];

  // what color the "+" circle icons take on.
  iconColor: string;
}
