<!-- ========== FOOTER ========== -->
<footer class="container">
  <div class="border-bottom space-2">
    <div class="row justify-content-lg-between">
      <div class="col-6 col-sm-6 col-lg-2 mb-7 mb-lg-0 centerMed">
        <h4 class="h6 text-white"><strong>About</strong></h4>


        <!-- List Group -->
        <ul class="list-group list-group-flush list-group-borderless mb-0">
          <li><a class="list-group-item list-group-item-action footer-link" routerLink="/helpful-information/about-us">About Us</a></li>
          <li><a class="list-group-item list-group-item-action footer-link" routerLink="/helpful-information/about-us" fragment="whybook">Why Book With Us</a></li>
          <li><a class="list-group-item list-group-item-action footer-link" routerLink="/helpful-information">Contact Us</a></li>
        </ul>
        <!-- End List Group -->
      </div>

      <div class="col-6 col-sm-6 col-lg-2 mb-7 mb-lg-0 centerMed">
        <h4 class="h6 text-white"><strong>Adventures</strong></h4>

        <!-- List Group -->
        <ul class="list-group list-group-flush list-group-borderless mb-0">
          <li><a class="list-group-item list-group-item-action footer-link" routerLink="/safari">Safari Tours</a></li>
          <li><a class="list-group-item list-group-item-action footer-link" routerLink="/kilimanjaro">Kilimanjaro Treks</a></li>
          <li><a class="list-group-item list-group-item-action footer-link" routerLink="/national-parks">Explore the Parks</a></li>
          <li><a class="list-group-item list-group-item-action footer-link" routerLink="/helpful-information/booking-and-rates">Booking & Rates</a></li>
        </ul>
        <!-- End List Group -->
      </div>

      <div class="col-sm-6 col-lg-4 col-md-6 mb-7 mb-lg-0">
        <h4 class="h6 text-white"><strong>Official Partners with Kopa Tours:</strong></h4>

        <!-- Button -->

        <!-- Button -->
        <a href="http://www.kilimanjaro-porters.org/" target="_blank" rel="noreferrer">
          <button type="button" class="btn btn-xs btn-footer btn-secondary btn-wide transition-3d-hover text-left mb-2 mr-1">
            <span class="media align-items-center">
              <img class="mr-3"
               width="48px" height="42px" alt="Mount Kilimanjaro Porter's Society"
               appWidth=48 appHeight=42
               appLazyload="logos/mkps.png">
              <span class="media-body">
                <strong class="font-size-1">MKPS</strong>
                <span class="d-block">Mount Kilimanjaro Porter's Society</span>
              </span>
            </span>
          </button>
        </a>
        <!-- End Button -->

        <!-- Button -->
        <a href="https://zaratours.com/" target="_blank" rel="noreferrer">
          <button type="button" class="btn btn-xs btn-footer btn-secondary btn-wide transition-3d-hover text-left mb-2">
            <span class="media align-items-center" >
              <img class="mr-3"
               width="51px" height="42px" alt="Zara Tours"
               appWidth=51 appHeight=42
               appLazyload="logos/zara_tours.png">
              <span class="media-body">
                <strong class="font-size-1">ZARA TOURS</strong>
                <span class="d-block">Tanzanian Adventure & Tour Operator</span>
              </span>
            </span>
          </button>
        </a>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6 text-right centerMed">
        <!-- Contacts -->
        <span class="h1 font-weight-semi-bold text-white">Contact Us</span>
        <small class="d-block font-weight-medium text-white">call us: <a class="font-weight-normal" href="tel:866-816-5672"><strong><u>866-816-5672</u></strong></a></small>
        <small class="d-block font-weight-medium text-white">email us: <a class="font-weight-normal" href="mailto:contact@kopatours.com"><strong><u>contact@kopatours.com</u></strong></a></small>
        <hr *ngIf="!isMobile()" style="width: 71%; margin-bottom: 0rem; margin-top: 5px; float: right">
        <small class="d-block font-weight-medium text-white mt-1 addrFooter"><img *ngIf="!isMobile()" class="usBadgeFooter" alt="American Flag"
                                                                  src="../../../../assets/vendor/flag-icon-css/flags/4x3/us.svg">28475 Greenfield Road Suite 113<br> Southfield, MI 48076
          <br *ngIf="isMobile()"><img *ngIf="isMobile()" class="usBadgeFooter" alt="American Flag"
               src="../../../../assets/vendor/flag-icon-css/flags/4x3/us.svg">
        </small>
        <!-- End Contacts -->

        <!-- Social Networks -->
        <ul class="list-inline mb-0 mt-5">
          <app-social-links></app-social-links>
        </ul>
        <!-- End Social Networks -->
      </div>
    </div>
  </div>

  <div class="row justify-content-sm-between align-items-md-center py-5">
    <div class="col-sm-12 col-md-6 mb-4 mb-sm-0 refundArea pb-2">
      <!-- List -->
      <ul class="list-inline list-group-flush list-group-borderless font-size-1 mb-0">
        <li class="list-inline-item pl-0 text-white">
          ©Kopa Tours.
        </li><br *ngIf="isLessThan400Px()">
        <li class="list-inline-item footer-link">
          <a routerLink="helpful-information/refund-policy">Refund Policy</a>
        </li>
        <li class="list-inline-item footer-link">
          <a routerLink="/helpful-information/terms-and-conditions">Terms</a>
        </li>
        <li class="list-inline-item footer-link">
          <a routerLink="helpful-information/privacy-policy">Privacy</a>
        </li>
      </ul>
      <!-- End List -->
    </div>

    <div class="col-sm-12 col-md-6 text-sm-right">
      <!-- Logo -->
      <span appCuteIcon class="footer-logo" folder="kopa-branding" type="full-logo-no-words"
            sizeInPixels="52" routerLink="../../home"></span>
      <span class="u-header__navbar-brand-text footer-kopa" routerLink="../../home">KOPA TOURS</span>
      <!-- End Logo -->
    </div>
  </div>
</footer>
<!-- ========== END FOOTER ========== -->
