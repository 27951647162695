import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.css']
})
export class SocialLinksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getPath(iconame: string) {
    return "/assets/svg/icons/brand-defs.svg#icon-" + iconame;
  }
}
