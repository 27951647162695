import {
    ItineraryBadge,
    ItineraryDetailsModel
} from '../../features/widgets-module/components/itinerary-card/itinerary-details.model';
import {ItineraryCardPhoto} from '../models/itinerary-card-photo.model';

export function getKiliItineraryCardsData() {
    return [
        new ItineraryDetailsModel(
            {
                title: 'Machame Route',
                price: '$2,454',
                starRating: 4,
                durationInDays: '6 or 7',
                detailBlurb: 'Hike the most popular trail on Mount Kilimanjaro.',
                link: '/kilimanjaro/machame-route',
                idForCard: 'machameRouteCard',
                badge: new ItineraryBadge({
                    isPresent: true,
                    color: 'copper',
                    text: 'Most Popular'
                }),
                photoSlides: [
                    new ItineraryCardPhoto(
                        {
                            path: 'assets/img/480x320/kilimanjaro-sunrise.jpg',
                            alt: 'Uhuru Peak sunrise view'
                        }
                    ),
                    new ItineraryCardPhoto(
                        {
                            path: 'assets/img/480x320/giant-sunflower-senecio-sm.jpg',
                            alt: 'Senecio tree'
                        }
                    ),
                    new ItineraryCardPhoto(
                        {
                            path: 'assets/img/1920x1080/campsite.jpg',
                            alt: 'Karanga Campsite'
                        }
                    )
                ]
            }
        ),
        new ItineraryDetailsModel(
            {
                title: 'Lemosho Route',
                price: '$2,999',
                starRating: 3.5,
                durationInDays: '8',
                detailBlurb: 'Climb the most scenic route on Mount Kilimanjaro.',
                link: '/kilimanjaro/lemosho-route',
                idForCard: 'lemoshoRouteCard',
                badge: new ItineraryBadge({
                    isPresent: true,
                    color: 'blue',
                    text: 'Highest Success Rate'
                }),
                photoSlides: [
                    new ItineraryCardPhoto(
                        {
                            path: 'assets/img/480x320/lava-tower-campsite.jpg',
                            alt: 'Lava Tower Campsite'
                        }
                    ),
                    new ItineraryCardPhoto(
                        {
                            path: 'assets/img/480x320/michael-peter-kilimanjaro-starry-sky.jpg',
                            alt: 'starry sky'
                        }
                    ),
                    new ItineraryCardPhoto(
                        {
                            path: 'assets/img/480x320/moorland-zone-sm.jpg',
                            alt: 'Heather and Moorland Zone'
                        }
                    )
                ]
            }
        ),
        new ItineraryDetailsModel(
            {
                title: 'Marangu Route',
                price: '$2,199',
                starRating: 2,
                durationInDays: '5',
                detailBlurb: 'Sleep in a cozy bunk bed, every night. The most affordable route!',
                link: '/kilimanjaro/marangu-route',
                idForCard: 'maranguRouteCard',
                badge: new ItineraryBadge({
                    isPresent: true,
                    color: 'dark',
                    text: 'Sleep in &nbsp;<s class="text-gray">Tents</s>&nbsp; Huts!'
                }),
                photoSlides: [
                    new ItineraryCardPhoto(
                        {
                            path: 'assets/img/480x320/marangu-huts.jpg',
                            alt: 'Marangu Route Huts in the Heather and Moorland Zone'
                        }
                    ),
                    new ItineraryCardPhoto(
                        {
                            path: 'assets/img/480x320/mount-kilimanjaro-glacial-freeze.jpg',
                            alt: 'Glacial Freeze on the Ridge of Mount Kilimanjaro'
                        }
                    ),
                    new ItineraryCardPhoto(
                        {
                            path: 'assets/img/480x320/marangu-campsite.jpg',
                            alt: 'Marangu Route Huts in the Rainforest Zone'
                        }
                    )
                ]
            }
        ),
    ];
}

export function getSafariItineraryCardsData() {
  return [
      new ItineraryDetailsModel(
          {
              title: 'Culture Quest Safari',
              price: '$2,079',
              starRating: 0,
              durationInDays: null,
              detailBlurb: `Visit Lake Manyara, Ngorongoro Crater, the Iraqw Culture Center, and
          Tarangire National Park on a wonderful cultural adventure.`,
              link: '/safari/culture-quest-safari',
              idForCard: 'cultureQuestSafariCard',
              badge: new ItineraryBadge({
                  isPresent: true,
                  color: 'primary',
                  text: 'Meet the Maasai'
              }),
              photoSlides: [
                  new ItineraryCardPhoto(
                      {
                          path: 'assets/img/480x320/vervet-monkey.jpg',
                          alt: 'monkey'
                      }
                  ),
                  new ItineraryCardPhoto(
                      {
                          path: 'assets/img/380x250/zebras-drinking.jpg',
                          alt: 'zebra'
                      }
                  ),
                  new ItineraryCardPhoto(
                      {
                          path: 'assets/img/480x320/african-warthog.jpg',
                          alt: 'warthog'
                      }
                  )
              ]
          }
      ),
      new ItineraryDetailsModel(
          {
              title: 'Big Buck Safari',
              price: '$2,519',
              starRating: 0,
              durationInDays: null,
              detailBlurb: `Visit Lake Manyara, Serengeti Ntl. Park, Ngorongoro Crater,
           and Tarangire Ntl. Park on this beautiful 5-day, 4-night safari.`,
              link: '/safari/big-buck-safari',
              idForCard: 'bigBuckSafariCard',
              badge: new ItineraryBadge({
                  isPresent: true,
                  color: 'blue',
                  text: 'Most Popular'
              }),
              photoSlides: [
                  new ItineraryCardPhoto(
                      {
                          path: 'assets/img/480x320/manyara-elephant.jpg',
                          alt: 'elephant'
                      }
                  ),
                  new ItineraryCardPhoto(
                      {
                          path: 'assets/img/380x250/buffalo-and-bird.jpg',
                          alt: 'buffalo'
                      }
                  ),
                  new ItineraryCardPhoto(
                      {
                          path: 'assets/img/480x320/baobab-tree.jpg',
                          alt: 'baobab'
                      }
                  )
              ]
          }
      ),
      new ItineraryDetailsModel(
          {
              title: 'Lion\'s Share Safari',
              price: '$3,289',
              starRating: 0,
              durationInDays: null,
              detailBlurb: `Visit Lake Manyara, Ngorongoro Crater, Olduvai Gorge, and the Serengeti Plains
          to see all that Tanzania has to offer.`,
              link: '/safari/lions-share-safari',
              idForCard: 'lionsShareSafariCard',
              badge: new ItineraryBadge({
                  isPresent: true,
                  color: 'primary',
                  text: 'Deluxe Experience'
              }),
              photoSlides: [
                  new ItineraryCardPhoto(
                      {
                          path: 'assets/img/480x320/cheetah-and-cubs.jpg',
                          alt: 'cheetah'
                      }
                  ),
                  new ItineraryCardPhoto(
                      {
                          path: 'assets/img/480x320/tarangire.jpg',
                          alt: 'tarangire'
                      }
                  ),
                  new ItineraryCardPhoto(
                      {
                          path: 'assets/img/480x320/baboon-family-manyara.jpg',
                          alt: 'baboon family'
                      }
                  )
              ]
          }
      )
  ];
}
