<svg class="fa-icon icon-{{iconName}}" [ngClass]="classes" id="{{id}}"
     [ngStyle]="{'width': width + 'em',
     'height': height + 'em',
     'fill': color ? 'var(--' + color + ')' : gradient ? 'url(#grad1)' : 'white',
     'margin-bottom': bottomMargin ? bottomMargin : '-.4375rem',
     'filter': shadow ? 'drop-shadow(1px 1px 1px black)' : ''}"><use [attr.xlink:href]="basePath"></use>
<defs>
  <linearGradient id="grad1" x1="0%" y1="0%" x2="200%" y2="200%">
    <stop offset="0%" style="stop-color:#fff;stop-opacity:1" />
    <stop offset="100%" style="stop-color:var(--primary);stop-opacity:1" />
  </linearGradient>
</defs>
</svg>
