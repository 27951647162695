import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  isMobile() {
      return document.body.clientWidth < 768;
  }

  isLessThan400Px() {
    return document.body.clientWidth < 375;
  }
}
