// core/component-loader.service.ts
import { Injectable, Compiler, Injector, Type, ViewContainerRef, ComponentFactoryResolver, NgModuleFactory, NgModuleRef, Inject } from '@angular/core';
import { LAZY_WIDGETS } from './tokens';
import { lazyWidgets } from './lazy-widgets';

let cachedModuleRefs: { [moduleName: string]: NgModuleRef<any>; } = {};

@Injectable()
export class LazyLoaderService {

  constructor(
    private injector: Injector,
    private compiler: Compiler,
    @Inject(LAZY_WIDGETS) private lazyWidgets: { [key: string]: () => Promise<NgModuleFactory<any> | Type<any>> }) { }

  async load(name: string): Promise<HTMLElement> {
    if (cachedModuleRefs[name] != null) {
      return new Promise(resolve => {
        const componentInstance = document.createElement(name);
        resolve(componentInstance);
      });
    } else {
      const ngModuleOrNgModuleFactory = await this.lazyWidgets[name]();

      let moduleFactory;

      if (ngModuleOrNgModuleFactory instanceof NgModuleFactory) {
        moduleFactory = ngModuleOrNgModuleFactory;
      } else {
        moduleFactory = await this.compiler.compileModuleAsync(ngModuleOrNgModuleFactory);
      }

      const entryComponent = (moduleFactory.moduleType as any).entry;
      const moduleRef = moduleFactory.create(this.injector).instance;
      cachedModuleRefs[name] = moduleRef;

      // instantiate the component
      const componentInstance = document.createElement(name);
      return componentInstance;
    }
  }
}
