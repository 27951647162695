import {Directive, Input, ElementRef, TemplateRef, ViewContainerRef} from '@angular/core';
import {VisibilityService} from '../../../core/services/visibility/visibility.service';
import {filter, take} from 'rxjs/operators';

@Directive({ selector: '[appVisibleWith]'})
export class VisibleWithDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private visibilityService: VisibilityService) {
  }

  @Input()
  set appVisibleWith(element) {
    this.visibilityService
        .elementInSight(new ElementRef(element))
        .pipe(filter(visible => visible), take(1))
        .subscribe(() => {
          this.viewContainer.createEmbeddedView(this.templateRef);
        });
  }
}
