import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-fa-icon',
  templateUrl: './fa-icon.component.html',
  styleUrls: ['./fa-icon.component.css']
})
export class FaIconComponent implements OnInit {

  @Input()
  iconName: string;

  @Input()
  width?: string;

  @Input()
  height?: string;

  @Input()
  color: string;

  @Input()
  gradient: boolean;

  @Input()
  shadow: boolean;

  @Input()
  classes: string;

  @Input()
  id = '';

  @Input()
  bottomMargin: string;

  constructor() {
  }

  basePath = '/assets/svg/icons/symbol-defs.svg#icon-';

  ngOnInit(): void {
    this.basePath = this.basePath + this.iconName;
  }

}
