import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-star-rating',
    template: `
    <app-fa-icon *ngFor="let star of stars"
     [iconName]="star ? 'star' : 'star-half'" [height]="size"
     color="warning" [shadow]="true">
    </app-fa-icon>`,
  styles: ['.shadowed {text-shadow: 2px 2px var(--dark);}']
})
export class StarRatingComponent implements OnInit {

  @Input()
  rating: number;

  @Input()
  size: string;

  stars: boolean[]; /* true = full, false = half */

  ngOnInit() {
    if (this.rating && this.rating > 0) {
      this.stars = Array(Math.floor(this.rating)).fill(true);
      if (this.rating % 1 !== 0) {
        this.stars.push(false);
      }
    }
  }
}
