import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HomePageComponent} from './core/components/home-page/home-page.component';
import {PageNotFoundComponent} from './core/components/page-not-found/page-not-found.component';

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot([
            {path: '', redirectTo: '/home', pathMatch: 'full',
            data: {
                metaBlob: {
                    title: 'Explore Tanzania | Kilimanjaro and Safari Trips',
                    description: `Travel with Kopa Tours to Tanzania - Get up close with Elephants, Rhinos, and Lions on Wild Camping safaris. Climb to the Roof of Africa atop Kilimanjaro!`,
                    twitterImage: '480x320/kilimanjaro-and-wildflowers-sm.jpg',
                    level: '0',
                    index: '0'
                }
            }
          },
            {
                path: 'home',
                component: HomePageComponent,
                data: {
                    metaBlob: {
                        title: 'Explore Tanzania | Kilimanjaro and Safari Trips',
                        description: `Travel with Kopa Tours to Tanzania - Get up close with Elephants, Rhinos, and Lions on Wild Camping safaris. Climb to the Roof of Africa atop Kilimanjaro!`,
                        twitterImage: '480x320/kilimanjaro-and-wildflowers-sm.jpg',
                        level: '0',
                        index: '0'
                    }
                }
            },
            {
                path: 'kilimanjaro',
                loadChildren: () => import('./features/kili-module/kili.module').then(m => m.KiliModule)
            },
            {
                path: 'hotels',
                loadChildren: () => import('./features/hotel-module/hotel.module').then(m => m.HotelModule)
            },
            {
                path: 'safari',
                loadChildren: () => import('./features/safari-module/safari.module').then(m => m.SafariModule)
            },
            {
                path: 'national-parks',
                loadChildren: () => import('./features/national-parks-module/national-parks.module').then(m => m.NationalParksModule)
            },
            {
                path: 'blog',
                loadChildren: () => import('./features/blog-module/blog.module').then(m => m.BlogModule)
            },
            {
                path: 'helpful-information',
                loadChildren: () => import(
                  './features/helpful-information-module/helpful-information.module').then(m => m.HelpfulInformationModule)
            },
            {
              path: 'page-not-found',
              component: PageNotFoundComponent,
              data: {
                  metaBlob: {
                      title: 'Page Not Found | 404',
                      description: `Oops! It looks like this page was not found on the Kopa Tours website.`,
                      statusCode: `404`,
                      level: '0',
                      index: '0'
                  }
              }
            },
            {path: '**', redirectTo: '/page-not-found'}
        ], { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
