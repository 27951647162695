<div class="hc-navoffset">
  <div #stickyHeader [@toggle]="getToggle"
       class="mobileStickinessNav">
    <app-nav-bar></app-nav-bar>
  </div>

  <!-- These are just the defaults -->
  <meta name="description"
        content="Travel with Kopa Tours to Tanzania - Get up close with Elephants, Rhinos, and Lions on Wild Camping safaris. Climb to the Roof of Africa atop Kilimanjaro!"/>

  <!-- twitter -->
  <meta name="twitter:card" content="summary_large_image"/>
  <meta name="twitter:site" content="@kopatours"/>
  <meta name="twitter:title" content="Kopa Tours | Kilimanjaro Hikes and Tanzanian Safaris"/>
  <meta name="twitter:description"
        content="Travel with us to Tanzania - Get up close with Elephants, Rhinos, and Lions on our Wild Camping safaris. Climb to the Roof of Africa atop Kilimanjaro!"/>
  <meta name="twitter:creator" content="@kopatours"/>
  <meta name="p:domain_verify" content="27b57243f41b861343b5e2264a26977f"/>

  <!-- facebook and other social sites -->
  <meta property="og:type" content="Site"/>
  <meta property="og:site_name" content="Kopa Tours"/>
  <meta property="og:title" content="Kopa Tours"/>
  <meta property="og:url" content="https://kopatours.com"/>
  <meta property="og:description"
        content="Travel with us to Tanzania - Get up close with Elephants, Rhinos, and Lions on our Wild Camping safaris. Climb to the Roof of Africa atop Kilimanjaro!"/>
  <meta property="og:image:type" content="image/jpeg"/>
  <meta property="og:image:alt" content="A view of Mount Kilimanjaro, with African wildflowers in the foreground"/>
  <!-- ========== MAIN CONTENT ========== -->
  <main id="content" role="main" class="mainBanner">
    <div #navDiv></div>
    <router-outlet></router-outlet>
    <button class="d-xl-none floatMenu btn btn-dark btn-circle btn-xl" (click)="loadNavMenu()">
    <span class="u-hamburger__box hc-nav-trigger">
      <span></span>
    </span>
      <br>
      <span style="font-size:8px;padding-top:-5px">M E N U</span>
    </button>
  </main>
  <div class="footerContainer">
    <footer class="col-12 bg-secondary position-relative z-index-2 gradientFade">
      <app-app-footer></app-app-footer>
    </footer>
  </div>
</div>
