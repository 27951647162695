import {Directive, Input, HostBinding, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Directive({
    selector: '[appCuteIcon]'
})
export class CuteIconDirective implements OnInit {

    @Input()
    folder = 'components';

    @Input()
    type: string;

    @Input()
    sizeInPixels = 64;

    @Input()
    sizeInEm: number;

    @Input()
    width: number;

    @Input()
    height: number;

    @HostBinding('innerHtml')
    public svg: SafeHtml = '';

    constructor(private sanitizer: DomSanitizer) {
      this.sizeInEm = -1;
    }

    ngOnInit() {
        const host = window.location.hostname;
        const fileExt = host === 'localhost' ? 'svg' : 'min.svg';

        if (document.body.clientWidth < 767 && window.location.pathname !== '/hotels') {
            if (this.type === 'facebook') {
                this.sizeInPixels = 26;
            } else if (this.type === 'instagram') {
              this.sizeInPixels = 32;
            }
        }

        if (this.type === 'africa' || this.type === 'flat-mountain') {
            this.svg = this.sanitizer.bypassSecurityTrustHtml(`
              <img class="lazyload"
               width="${this.width}px"
               height="${this.height}px"
               data-src="../../../../../assets/svg/${this.folder}/${this.type}.${fileExt}"
               alt="${this.type} cute icon"
              >
            `);
        } else if (this.type) {
            let sizeStr = this.sizeInPixels + "px";
            if (this.sizeInEm > 0) {
              sizeStr = this.sizeInEm + "rem";
            }
            this.svg = this.sanitizer.bypassSecurityTrustHtml(`
              <img class="lazyload"
               width="${sizeStr}"
               height="${sizeStr}"
               data-src="../../../../../assets/svg/${this.folder}/${this.type}.${fileExt}"
               alt="${this.type} cute icon"
              >
            `);
        } else {
            this.svg = `
        <span class="btn btn-sm btn-icon btn-soft-dark rounded-circle mr-3 px-2-5 px-xl-0">
        </span>
      `;
        }
    }
}
