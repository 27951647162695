import {Component, OnInit} from '@angular/core';
import {Testimonial} from './testimonials-classes';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  // metadata for the testimonial entries.
  testimonials: Testimonial[];

  constructor() {
    this.testimonials = [
      new Testimonial({
        description: `Our 30th wedding anniversary dinner at the
         Serengeti Safari Lodge was SUCH a treat. Thank you Kopa Tours!`,
        person: 'Jill & Dennis Collins',
        location: 'Whitefish, MT, USA',
        thumbnailPath: '100x100/testimonial-1.png',
        altThumbnailDescription: 'Jill and Dennis Collins - Kopa Tours'
      }),
      new Testimonial({
        description: `Kopa Tours was in contact throughout the whole planning process ..
        from what size shoes to wear, to what level of sun screen protection,
        were ready to help for anything.`,
        person: 'María Alvarez',
        location: 'San Diego, CA, USA',
        thumbnailPath: '100x100/testimonial-6.png',
        altThumbnailDescription: 'Maria Alvarez - Kopa Tours'
      }),
      new Testimonial({
        description: `I hiked the Machame Route (6 day) on a last minute booking.
         Kopa did a pretty good job making sure everything was set up in time. Made it to the top!`,
        person: 'Brent Hudson',
        location: 'Brooklyn, NY, USA',
        thumbnailPath: '100x100/testimonial-7.png',
        altThumbnailDescription: 'Brent Hudson - Kopa Tours'
      }),
      new Testimonial({
        description: `I and my 3 lifelong best friends had a wonderful time
         at the Serengeti. Hotels were nice and comfy. LOVED the butler service! Thanks, Danford!!`,
        person: 'Patricia Rogers',
        location: 'Henderson, NV, USA',
        thumbnailPath: '100x100/testimonial-5.png',
        altThumbnailDescription: 'Brent Hudson - Kopa Tours'
      })
    ];

  }

  ngOnInit() {
  }
}
