import {Component, OnInit} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    $(window).scroll(() => {
      if ($(window).scrollTop() >= 50 && document.body.clientWidth > 991.98) {
        document.getElementById('logoAndNav').classList.add('sticky-border');
      } else {
        document.getElementById('logoAndNav').classList.remove('sticky-border');
      }
    }).scroll();
  }

  public isTooSmall() {
    return document.body.clientWidth <= 360;
  }

}
