<ng-template #ico let-iconame='iconame' let-link="link">
  <li class="list-inline-item mb-2 mb-sm-0">
    <a class="btn btn-sm btn-icon btn-soft-white rounded-circle"
       [attr.href]="link" target="_blank" rel="noopener">
       <svg class="icon icon-{{iconame}} btn-icon__inner"><use [attr.xlink:href]="getPath(iconame)"></use></svg>
    </a>
  </li>
</ng-template>

<ng-container *ngTemplateOutlet="ico, context: {'iconame': 'facebook-f', 'link': 'https://www.facebook.com/KopaTours/'}"></ng-container>
<ng-container *ngTemplateOutlet="ico, context: {'iconame': 'instagram', 'link': 'https://www.instagram.com/kopatours/'}"></ng-container>
<ng-container *ngTemplateOutlet="ico, context: {'iconame': 'twitter', 'link': 'https://twitter.com/kopatours'}"></ng-container>
<ng-container *ngTemplateOutlet="ico, context: {'iconame': 'youtube', 'link': 'https://www.youtube.com/channel/UCzp0DQ0jEJuqgaa8kfD1-aQ'}"></ng-container>
<ng-container *ngTemplateOutlet="ico, context: {'iconame': 'tripadvisor', 'link': 'https://www.tripadvisor.com/Attraction_Review-g317084-d19493872-Reviews-Kopa_Tours-Moshi_Kilimanjaro_Region.html'}"></ng-container>
<li class="list-inline-item mb-2 mb-sm-0">
  <a class="btn btn-sm btn-icon btn-soft-white rounded-circle blogTopBut" routerLink="./blog">
    <svg class="icon icon-blogger btn-icon__inner"><use [attr.xlink:href]="getPath('blogger')"></use></svg>
  </a>
</li>
