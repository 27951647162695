export const Schema = {
 "@context": "https://schema.org",
 "@type": "Organization",
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "Southfield, Michigan, United States",
    "postalCode": "48076",
    "streetAddress": "28475 Greenfield Rd Suite 113"
  },
 "name": "Kopa Tours",
 "legalName" : "Kopa Tours LLC",
 "url": "https://kopatours.com",
 "logo": "https://kopatours.com/assets/img/720x720/full-kopa-logo.png",
 "foundingDate": "2019",
 "contactPoint": {
  "@type" : "ContactPoint",
  "telephone" : "+1-866-816-KOPA",
  "contactType" : "Customer Support",
  "contactOption" : "TollFree",
  "areaServed" : "US",
  "availableLanguage" : ["English"],
  "email": "contact@kopatours.com"
  },
 "sameAs": [
 "https://www.facebook.com/KopaTours/",
 "https://twitter.com/kopatours",
 "https://www.tripadvisor.com/Attraction_Review-g317084-d19493872-Reviews-Kopa_Tours-Moshi_Kilimanjaro_Region.html",
 "https://instagram.com/kopatours",
 "https://www.pinterest.com/kopatours/",
 "https://www.youtube.com/channel/UCzp0DQ0jEJuqgaa8kfD1-aQ",
 "https://www.bookmundi.com/companies/kopa-tours/c2163"
 ]
};
