import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ItineraryAccordionComponent} from './components/itinerary-accordion/itinerary-accordion.component';
import {BookingCalendarComponent} from './components/booking-calendar/booking-calendar.component';
import {ItineraryCardComponent} from './components/itinerary-card/itinerary-card.component';
import {KiliTableComponent} from './components/kili-table/kili-table.component';
import {AnimalRaritiesComponent} from './components/animal-rarities/animal-rarities.component';
import {TripListComponent} from './components/trip-list/trip-list.component';
import {IconListComponent} from './components/icon-list/icon-list.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {RouterModule} from '@angular/router';
import {DirectivesModule} from '../directives-module/directives.module';
import {JwPaginationComponent} from './components/jw-angular-pagination/jw-pagination.component';
import {TestimonialsComponent} from './components/testimonials/testimonials.component';
import {PerPageNavigationComponent} from './components/per-page-navigation/per-page-navigation.component';
import {HotelComponent} from "./components/hotel/hotel.component";
import {AgmCoreModule} from "@agm/core";
import {AmenitiesComponent} from "./components/amenities/amenities.component";
import {NationalParkComponent} from "./components/national-park/national-park.component";
import {FactsBoxComponent} from "./components/facts-box/facts-box.component";
import {MobileBookButtonComponent} from "./components/mobile-book-button/mobile-book-button.component";
import {StarRatingComponent} from './components/star-rating/star-rating.component';
import {BsModalService, ModalBackdropComponent, ModalContainerComponent} from "ngx-bootstrap/modal";
import {InclusionExclusionListComponent} from './components/inclusion-exclusion-list/inclusion-exclusion-list.component';
import {InclusionExclusionListKiliComponent} from './components/inclusion-exclusion-list-kili/inclusion-exclusion-list-kili.component';
import {InclusionExclusionListSafariComponent} from './components/inclusion-exclusion-list-safari/inclusion-exclusion-list-safari.component';
import {RequestDiscountVoucherComponent} from './components/request-discount-voucher/request-discount-voucher.component';
import {StickyFooterButtonComponent} from './components/sticky-footer-button/sticky-footer-button.component';
import {KiliCtaComponent} from './components/kili-cta/kili-cta.component';
import {ClimbReviewsComponent} from "./components/climb-reviews/climb-reviews.component";
import {IvyCarouselModule} from 'angular-responsive-carousel';
import {InformationPageComponent} from "./components/information-page/information-page.component";
import {GritCarouselComponent} from './components/grit-carousel/grit-carousel.component';
import {InformationPageCtaComponent} from './components/information-page-cta/information-page-cta.component';
import {GritResultComponent} from './components/grit-result/grit-result.component';
import {AwardsAndPressComponent} from './components/awards-and-press/awards-and-press.component';
import { FaIconComponent } from './components/fa-icon/fa-icon.component';
import { KopaButtonComponent } from './components/kopa-button/kopa-button.component';

@NgModule({
  declarations: [
    AnimalRaritiesComponent,
    ItineraryAccordionComponent,
    TripListComponent,
    IconListComponent,
    HotelComponent,
    BookingCalendarComponent,
    ItineraryCardComponent,
    KiliTableComponent,
    JwPaginationComponent,
    TestimonialsComponent,
    PerPageNavigationComponent,
    HotelComponent,
    AmenitiesComponent,
    NationalParkComponent,
    FactsBoxComponent,
    MobileBookButtonComponent,
    StarRatingComponent,
    InclusionExclusionListComponent,
    InclusionExclusionListKiliComponent,
    InclusionExclusionListSafariComponent,
    RequestDiscountVoucherComponent,
    StickyFooterButtonComponent,
    KiliCtaComponent,
    ClimbReviewsComponent,
    InformationPageComponent,
    InformationPageCtaComponent,
    GritCarouselComponent,
    GritResultComponent,
    AwardsAndPressComponent,
    FaIconComponent,
    KopaButtonComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    RouterModule,
    DirectivesModule,
    AgmCoreModule,
    IvyCarouselModule
  ],
  exports: [
    AnimalRaritiesComponent,
    ItineraryAccordionComponent,
    TripListComponent,
    IconListComponent,
    HotelComponent,
    BookingCalendarComponent,
    ItineraryCardComponent,
    KiliTableComponent,
    JwPaginationComponent,
    TestimonialsComponent,
    PerPageNavigationComponent,
    FactsBoxComponent,
    NationalParkComponent,
    MobileBookButtonComponent,
    StarRatingComponent,
    InclusionExclusionListKiliComponent,
    InclusionExclusionListSafariComponent,
    RequestDiscountVoucherComponent,
    StickyFooterButtonComponent,
    KiliCtaComponent,
    ClimbReviewsComponent,
    InformationPageComponent,
    InformationPageCtaComponent,
    GritCarouselComponent,
    GritResultComponent,
    AwardsAndPressComponent,
    FaIconComponent,
    KopaButtonComponent
  ],
  providers: [
    BsModalService
  ],
  entryComponents: [
    ModalBackdropComponent,
    ModalContainerComponent,
    RequestDiscountVoucherComponent
  ]
})
export class WidgetsModule {
}
