import {NgModule} from '@angular/core';
import {LazyloadDirective} from './lazyload/lazyload.directive';
import {CuteIconDirective} from './cute-icon/cute-icon.directive';
import {VisibleWithDirective} from './visible-with/visible-with.directive';
import {VisibilityService} from '../../core/services/visibility/visibility.service';
import {FancyPhotoGalleryDirective} from './fancy-photo-gallery/fancy-photo-gallery.directive';
import {ElevationGraphDirective} from './elevation-graph/elevation-graph.directive';
import {SurveyDirective} from './survey/survey.directive';
import {ScrollSpyDirective} from './scroll-spy/scroll-spy.directive';
import {LazyLoaderService} from '../../core/services/lazy-loader/lazy-loader.service';
import {lazyArrayToObj} from '../../core/services/lazy-loader/lazy-widgets';
import {LAZY_WIDGETS} from '../../core/services/lazy-loader/tokens';

@NgModule({
  imports: [],
  declarations: [
    LazyloadDirective, CuteIconDirective, FancyPhotoGalleryDirective,
    ElevationGraphDirective, SurveyDirective, VisibleWithDirective, ScrollSpyDirective
  ],
  exports: [
    LazyloadDirective, CuteIconDirective, FancyPhotoGalleryDirective,
    ElevationGraphDirective, SurveyDirective, VisibleWithDirective, ScrollSpyDirective
  ],
  providers: [
    { provide: LAZY_WIDGETS, useFactory: lazyArrayToObj },
    LazyLoaderService,
    VisibilityService
  ]
})
export class DirectivesModule { }
