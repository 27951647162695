import {Component, Input, OnInit} from '@angular/core';
import {ItineraryDetailsModel} from './itinerary-details.model';
import {ItineraryCardPhoto} from '../../../../core/models/itinerary-card-photo.model';
import {isSmallerThanIpad} from '../../../../../helpers';

@Component({
  selector: 'app-itinerary-card',
  templateUrl: './itinerary-card.component.html',
  styleUrls: ['./itinerary-card.component.css']
})
export class ItineraryCardComponent implements OnInit {

  @Input()
  ultraGlow: boolean;

  @Input()
  safari: boolean;

  @Input()
  itineraryDetails: ItineraryDetailsModel;

  photos: ItineraryCardPhoto[] = [];

  constructor() {
    this.ultraGlow = false;
  }

  ngOnInit() {
    this.photos = this.itineraryDetails.photoSlides;
  }

  isSmallerThanIpad() {
    return isSmallerThanIpad();
  }

  isUnevenSize() {
    return document.body.clientWidth >= 1024 && document.body.clientWidth <= 1200;
  }
}
