import {Injectable, EventEmitter} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  public surveyComplete: EventEmitter<JSON>;

  public routeStatTrackers: {[key: string]: StatTrack} = {
    "machame-route": new StatTrack("172676"),
    "marangu-route": new StatTrack("192423"),
    "lemosho-route": new StatTrack("192431")
  };

  public surveyRouteResult: string;
  public surveyGritResult: number;

  constructor() {
    this.surveyComplete = new EventEmitter();
  }

  public triggerSurveyComplete(result: JSON, quizType: string): any {
    if(quizType === 'route') {
      this.surveyRouteResult = this.evaluateQuizResult(result, this.routeStatTrackers);
    } else {
      this.surveyGritResult = this.evaluateGritScore(result);
    }
    this.surveyComplete.emit(result);
    return quizType === 'route' ? this.surveyRouteResult : this.surveyGritResult;
  }

  public evaluateQuizResult(res: JSON, statTrackers: any): string {
    switch (res["partyType"]) {
      case "family":
        statTrackers["machame-route"].add(null, 2);
        break;
      case "friends":
        statTrackers["lemosho-route"].add(null, 1);
        break;
      case "solo":
        statTrackers["machame-route"].add(null, 1);
        break;
      case "partner":
        statTrackers["machame-route"].add(null, 1);
        break;
    }

    switch (res["hikingAbility"]) {
      case "beginner":
        statTrackers["lemosho-route"].add(null, 2);
        break;
      case "intermediate": {
        statTrackers["marangu-route"].add(null, 1);
        statTrackers["machame-route"].add(null, 1);
        statTrackers["lemosho-route"].add(null, 1);
        break;
      }
      case "advanced": {
        statTrackers["marangu-route"].add(null, 2);
        statTrackers["machame-route"].add(null, 1);
        break;
      }
    }

    switch (res["preparation"]) {
      case "preparedNearFuture":
        statTrackers["machame-route"].add(null, 1);
        statTrackers["lemosho-route"].add(null, 1);
        break;
      case "unsureIfKiliIsRight": {
        statTrackers["lemosho-route"].add(null, 1);
        break;
      }
      case "confidentReadyToGo": {
        statTrackers["marangu-route"].add(null, 1);
        statTrackers["machame-route"].add(null, 2);
        break;
      }
      case "planningForLater": {
        statTrackers["lemosho-route"].add(null, 1);
        break;
      }
    }

    if (res.hasOwnProperty("achievements")) {
      for (let i = 0, l = res["achievements"].length; i < l; i++) {
        if (res["achievements"][i] === "hiking") {
          statTrackers["machame-route"].add(null, 2);
          statTrackers["marangu-route"].add(null, 1);
          continue;
        }
        if (res["achievements"][i] === "run-half-marathon") {
          statTrackers["machame-route"].add(null, 1);
          continue;
        }
        if (res["achievements"][i] === "backpacking") {
          statTrackers["machame-route"].add(null, 1);
          statTrackers["lemosho-route"].add(null, 1);
          continue;
        }
        if (res["achievements"][i] === "everest") {
          statTrackers["lemosho-route"].add(null, 1);
          continue;
        }
        if (res["achievements"][i] === "workouts") {
          statTrackers["marangu-route"].add(null, 1);
          continue;
        }
      }
    }

    switch (res["imagepicker"]) {
      case "reserved":
        statTrackers["lemosho-route"].add(null, 1);
        break;
      case "showy": {
        statTrackers["marangu-route"].add(null, 1);
        statTrackers["machame-route"].add(null, 1);
        break;
      }
      case "anxious":
        statTrackers["lemosho-route"].add(null, 1);
        break;
      case "confident":
        statTrackers["machame-route"].add(null, 1);
        break;
    }

    for (let j = 0; j < 3; j++) {
      if (res["priorities"][j] === "Beauty of the Scenery") {
        statTrackers["machame-route"].add(null, 1);
        statTrackers["lemosho-route"].add(null, 1);
        continue;
      }
      if (res["priorities"][j] === "Saving on Price") {
        statTrackers["marangu-route"].add(null, 3);
        statTrackers["machame-route"].add(null, 2);
        continue;
      }
      if (res["priorities"][j] === "Knowing everything is planned for me") {
        statTrackers["machame-route"].add(null, 1);
        statTrackers["lemosho-route"].add(null, 1);
        continue;
      }
      if (res["priorities"][j] === "Getting to the Summit") {
        statTrackers["machame-route"].add(null, 1);
        statTrackers["lemosho-route"].add(null, 2);
        continue;
      }
      if (res["priorities"][j] === "Being comfortable on the mountain" && j <= 1) {
        statTrackers["marangu-route"].add(null, 3);
        continue;
      }
    }

    let max = "machame-route";
    if (statTrackers[max].score < statTrackers["lemosho-route"].score) {
      max = "lemosho-route";
    }
    if (statTrackers[max].score < statTrackers["marangu-route"].score) {
      max = "marangu-route";
    }
    return max;
  }

  public evaluateLeadScore(res: JSON) {
    let leadScore = 0;

    if (res['preparation'] === 'preparedNearFuture') {
      leadScore += 3;
    } else if  (res['preparation'] === 'confidentReadyToGo') {
      leadScore += 6;
    }

    if (res['imagepicker'] === 'confident' || res['imagepicker'] === 'showy') {
      leadScore += 1;
    }

    if (res['achievements'].indexOf('run-half-marathon') > -1 || res['achievements'].indexOf('everest') > -1) {
      leadScore += 5;
    }
    if (res['achievements'].indexOf('backpacking') > -1) {
      leadScore += 3;
    }
    if (res['achievements'].indexOf('other') > -1) {
      leadScore += 3;
    }

    if (res['hikingAbility'] === 'intermediate') {
      leadScore += 3;
    } else if (res['hikingAbility'] === 'advanced') {
      leadScore += 4;
    }

    const topChoice = res['priorities'][0];
    const knowingPlanIndex = res['priorities'].indexOf('Knowing everything is planned for me');
    const priceSavingIndex = res['priorities'].indexOf('Saving on Price');
    if (topChoice === 'Getting to the Summit') {
      leadScore += 2;
    }
    leadScore += ((4 - knowingPlanIndex) * 1.5);
    if (priceSavingIndex > 1) {
      leadScore += (priceSavingIndex * 2);
    }

    let returnValue = '';
    res['leadScore'] = leadScore;
    if (leadScore > 25) {
      returnValue = 'really-good-lead';
    } else if (leadScore > 12) {
      returnValue = 'potential-lead';
    } else {
      returnValue = 'not-a-lead';
    }

    return returnValue;
  }

  public evaluateGritScore(result: JSON): number {
    let gritScore = 0.5;

    //Question 1
    if (result["idealProj"] === 'confident') {
      gritScore += 1.0;
    } else if(result['idealProj'] === 'challenge') {
      gritScore += 1.5;
    }

    //Question 2
    if (result["deal-failure"] > 3) {
      gritScore += 1.0;
    } else {
      gritScore += 0.5;
    }

    //Question 2
    if (result["deal-stress"] > 3) {
      gritScore += 1.5;
    } else {
      gritScore += 0.5;
    }

    //Question 3
    if(result["makeAPlan"] === 'adapt') {
      gritScore += 1.5;
    } else if(result["makeAPlan"] === 'stickToIt') {
      gritScore += 0.5;
    }

    //Question 4
    if(result["longProjectFeel"] === 'invigorated') {
      gritScore += 1.5;
    } else if(result["longProjectFeel"] === 'pride') {
      gritScore += 0.5;
    }

    //Question 5
    if(result["difficultSituation"] === 'lookForSupport') {
      gritScore += 1.0;
    } else if(result["difficultSituation"] === 'acceptSupport') {
      gritScore += 0.5;
    } else if(result["difficultSituation"] === 'sole') {
      gritScore += 0.2;
    } else if(result['difficultSituation'] === 'avoid') {
      gritScore -= 1.5;
    }

    //Question 6
    if(result["competition"] === 'unsureWin') {
      gritScore += 1.5;
    } else if(result["competition"] === 'cantLose') {
      gritScore += 0.5;
    } else if(result["competition"] === 'fun') {
      gritScore += 0.2;
    }

    if(result['imagepicker'] === 'kate') {
      gritScore -= 1;
    }

    if(gritScore < 2) {
      return 2.5;
    }

    return gritScore;
  }

  public getGritCategory(gritScore: number) {
    if(gritScore <= 3.7) {
      return 'not-gritty';
    } else if(gritScore > 3.7 && gritScore <= 5) {
      return 'somewhat-gritty';
    } else if(gritScore > 5 && gritScore <= 6.5) {
      return 'gritty';
    } else if(gritScore > 6.5 && gritScore <= 8) {
      return 'very-gritty';
    } else if(gritScore > 8) {
      return 'pure-grit';
    }
  }
}

class StatTrack {
  score: number;
  remarks: string[];
  calendarId: string;

  add(remark: string, scoreInc: number) {
    if (remark && remark.length) {
      this.remarks.push(remark);
    }
    this.score += scoreInc;
  }

  constructor(calId: string) {
    this.score = 0;
    this.remarks = []; // personalized details presented to the user.
    this.calendarId = calId;
  }
}
