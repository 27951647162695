import {AfterViewInit, Component, Input, OnInit, Output} from '@angular/core';
import {EventEmitter} from "events";
declare var gtag: any;

@Component({
  selector: 'app-kopa-button',
  templateUrl: './kopa-button.component.html',
  styleUrls: ['./kopa-button.component.css']
})
export class KopaButtonComponent implements OnInit, AfterViewInit{

  @Input()
  link: string = '';

  @Input()
  color: string;

  @Input()
  text: string;

  @Input()
  classes?: string;

  @Input()
  fragment: string;

  @Input()
  chevronDir = 'right';

  @Input()
  blockOrPill = 'pill';

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  }


}
