export function isSmallerThanIpad() {
    return document.body.clientWidth <= 767;
}

export function getFancyboxHeightCLS() {
  const width = document.body.clientWidth;
  if (width < 500) {
    return '240px';
  } else if (width >= 500 && width <= 768) {
    return '320px';
  } else if(width > 768 && width <= 992) {
    return '220px';
  } else if (width > 992) {
    return '152px';
  }
  return '152px';
}

//
// export function getFancyboxHeightCLS() {
//   const width = document.body.clientWidth;
//   if (width < 500) {
//     return '240px';
//   } else if (width >= 500 && width <= 768) {
//     return '320px';
//   } else if(width > 768 && width <= 992) {
//     return '220px';
//   } else if (width > 992) {
//     return '152px';
//   }
//   return '152px';
// }

export function getCalendarCardHeightCLS() {
  if(isSmallerThanIpad()) {
    return '828px';
  } else {
    return '820px';
  }
}
