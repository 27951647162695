import {ItineraryCardPhoto} from '../../../../core/models/itinerary-card-photo.model';

export class ItineraryDetailsModel {
    constructor(options?: {
        title: string; price: string;
        starRating: number; durationInDays: string;
        detailBlurb: string; link: string; idForCard: string;
        badge: ItineraryBadge; photoSlides: ItineraryCardPhoto[]
      }) {
        if (options) {
            this.title = options.title;
            this.price = options.price;
            this.starRating = options.starRating;
            this.durationInDays = options.durationInDays;
            this.detailBlurb = options.detailBlurb;
            this.link = options.link;
            this.badge = options.badge;
            this.idForCard = options.idForCard;
            this.photoSlides = options.photoSlides;
        }
    }

    title: string;
    price: string;
    starRating: number;
    durationInDays: string;
    detailBlurb: string;
    link: string;
    idForCard: string;
    badge: ItineraryBadge;
    photoSlides: ItineraryCardPhoto[];
}

export class ItineraryBadge {
    isPresent: boolean;
    color: string;
    text: string;

    constructor(options?: {isPresent: boolean; color: string; text: string}) {
        if (options) {
            this.isPresent = options.isPresent;
            this.color = options.color;
            this.text = options.text;
        }
    }
}
