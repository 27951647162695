<div
        [ngClass]="{'card-frame-ultra-highlighted':ultraGlow, 'card-frame-highlighted':!ultraGlow}"
        class="card card-frame overflow-hidden card-frame-highlighted itinerary-card text-left mb-3">

    <carousel id="{{itineraryDetails.idForCard}}"
         cellsToShow="1"
         [margin]=0
         [overflowCellsLimit]=1
         [lightDOM]=true
         [images]=photos>
    </carousel>

    <div class="card-body" routerLink="{{itineraryDetails.link}}">
        <h5 class="card-title">{{itineraryDetails.title}}&nbsp;<app-fa-icon iconName="link" *ngIf="isSmallerThanIpad()" color="white"></app-fa-icon>
          <app-star-rating class="float-right" [size]="'1.3'" [rating]="itineraryDetails.starRating"></app-star-rating>
          <br>
          <i>
            <span *ngIf="itineraryDetails.badge.isPresent"
                  class="badge badge-{{itineraryDetails.badge.color}} px-2 py-1 mt-1"
                  [innerHTML]="itineraryDetails.badge.text"></span>
          </i>
          <span *ngIf="itineraryDetails.durationInDays && itineraryDetails.durationInDays.length > 0" class="badge font-weight-bold pt-2 float-right">
            {{itineraryDetails.durationInDays}} day hike
          </span>
        </h5>
        <br *ngIf="!itineraryDetails.badge.isPresent">
        <p class="card-text description">{{itineraryDetails.detailBlurb}}</p>
        <app-kopa-button *ngIf="!safari" color="primary" text="Show Me More" link="{{itineraryDetails.link}}" classes="btn-block"></app-kopa-button>

<!--      <button *ngIf="!safari" type="button" class="btn btn-primary btn-pill btn-block shadowed">Show Me More<app-fa-icon iconName="chevron-right" class="ml-2" height="1.5" width=".75" ></app-fa-icon></button>-->
        <p class="card-text smallText pb-0 mb-0" *ngIf="safari">Starting at:</p>
        <p class="card-text price" *ngIf="safari">{{itineraryDetails.price}} / person</p>
    </div>
</div>
