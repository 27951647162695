export class ItineraryCardPhoto {
    path: string;
    alt: string;

    constructor(options?: {path: string, alt: string}) {
        if (options) {
            this.path = options.path;
            this.alt = options.alt;
        }
    }
}
