<div class="position-relative aboutUsBackground">
  <div class="container d-xs-flex align-items-xs-center space-top-2 space-top-sm-2 space-xs-0 min-height-xs-100vh">
      <div class="row mt-11 pb-2 ml-3 ml-lg-7">
              <h1><span class="text-fg-alt font-weight-bold">404</span> - <br class="clearfix d-inline d-md-none" /> There's nothing here!</h1>
      </div>
      <div class="row pb-2 ml-3 ml-lg-7 space-bottom-2 space-top-1">
        <div class="col-12 col-md-3">
            <app-fa-icon iconName="heart-broken" classes="ml-2 big-heart" color="primary"></app-fa-icon>
        </div>
        <div class="col-12 col-md-8 my-md-auto ml-md-6 mt-4">
          <h3>
            Maybe we messed up, or maybe the page doesn't exist.<br />Why not take it <a routerLink="/home"><u>from the top</u></a>?
          </h3>
        </div>
      </div>
  </div>
</div>
